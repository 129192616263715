.baotri.app {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
    radial-gradient(at top center, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.4) 120%) #989898;
  background-blend-mode: multiply, multiply;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.baotri .container {
  max-width: 1150px;
  display: flex;
  justify-content: center;
}

.baotri .container > div {
  width: 50%;
}

.baotri .content {
  color: white;
}

.baotri .title {
  margin-top: 30px;
  font-size: 1.5em;
  font-weight: 800;
}

.baotri .sub-title {
  margin-top: 120px;
  font-size: 3.7em;
  font-weight: 800;
}

.baotri .sub-content {
  margin-top: 30px;
  font-size: 1.2em;
}

.baotri .img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.baotri .img-baotri {
  height: auto;
  width: 600px;
}

@media only screen and (max-device-width: 1150px) {
  .baotri .container {
    max-width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .baotri .title {
    margin-top: 0px;
    font-size: 1.5em;
    font-weight: 800;
  }

  .baotri .sub-title {
    margin-top: 10px;
    font-size: 1.5em;
    font-weight: 800;
  }

  .baotri .sub-content {
    margin-top: 30px;
    font-size: 1em;
    margin-bottom: 30px;
  }

  .baotri .img {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .baotri img {
    height: auto;
    max-width: 370px;
  }

  .baotri .container > div {
    width: 100%;
  }
}
