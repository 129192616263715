html {
    height: 100%;
}

.body {
    margin: 0;
    height: 100vh;
    /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
    font-family: 'Times New Roman';
}
.modal-custom-body .modal-custom {
    opacity: 1;
    visibility: unset;
}
.modal-custom{
    position: fixed;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.55);
    -webkit-tap-highlight-color: transparent;
    inset: 0px;
    z-index: 100;
}

.modal-custom-body .warning-message{
    display: none;
}

.modal-custom-body .popup .warning-message{
    display: block;
    margin-left: 16px;
    margin-top: 4px;
    font-size: 12px;
    margin-bottom: 12px;
}

.align-right {
    text-align: right;
}

.body-content {
    position: absolute;
    right: 10vw;
    top: 42%;
    transform: translateY(-50%);
    max-width: 650px;
    /* max-height: 570px;
    height: 100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9;
}

.promo-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../images/background.jpg');
}

.thumb-logo {
    max-width: 300px;
    margin: auto;
    display: flex;
}

.promo-logo {
    width: 100%;
    height: auto;
}

.promo-form {
    text-align: center;
    opacity: 1;
    border-radius: 20px;
    background-color: #38415433;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 650px;
    margin-top: 2em;
    padding: 0 2em 1em 2em;
}

.promo-title {
    display: inline-block;
    opacity: 1;
    border-radius: 20px;
    background-color: #38415475;
    mix-blend-mode: hard-light;
    border-radius: 5px;
    padding: 10px;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: auto;
    letter-spacing: 1%;
    text-align: justify;
    color: white;
    text-shadow: 0 2px 1px #fe7e0d, 0 2px 0 #ff9831;
}

.input-container {
    margin: 20px;
}

.promo-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: yellow;
    margin-bottom: 10px;
}

.label-text {
    margin-right: 5px;
    color: #FF9800;
    margin-left: 5px;
}

.promo-input {
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    background-color: transparent;
    border: solid 1px white;
    border-radius: 10px;
    color: white;
}

.promo-input::placeholder {
    color: #D3D3D3;
    font-size: 16px;
}

.promo-button {
    width: 100%;
    color: white;
    display: block;
    margin-top: 1em;
    background: linear-gradient(to right,
            #C34029 0%,
            #FF7214 24%,
            #DD600F 49%,
            #FF7214 74%,
            #C34029 100%);
    padding: 15px 30px;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 15px;
}

.promo-link {
    display: inline-block;
    font-size: 16px;
    background-color: #07080a7a;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 500;
    position: relative;
    text-transform: uppercase;
}
.promo-link-a{
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.promo-link:hover {
    background-color: #e0e0e0d4;
    color: #000;
    cursor: pointer;
}
.promo-link-a:hover {
    color: #000;
    cursor: pointer;
}

.promo-links {
    display: flex;
    justify-content: space-between;
}

.arrow {
    width: 22px;
    height: 22px;
    margin-left: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.popup {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1000;
}

.popup h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 1%;
    text-align: center;
}

.popup p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-top: 20px;
}

.popup p span {
    font-size: 16px;
    font-weight: 600;
}

.popup table {
    width: 100%;
    max-width: 370px;
    margin-bottom: 20px;
    margin-left: 1em;
    margin-right: 1em;
}

.popup table td {
    padding: 5px;
}

.popup table td:first-child {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px
}

.popup table td:last-child {
    text-align: right;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px
}

.pointbonus {
    color: green;
}

.outer-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #D0F1DD;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-circle {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #D0F1DD;
    border: solid 5px white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkmark {
    width: 10px;
    height: 25px;
    border: solid #389E0D;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
}

p.text-account-input {
    font-weight: 600;
    font-size: 14px;
}

.account-input {
    padding: 10px 0 10px 10px;
    width: 100%;
    max-width: 370px;
    display: block;
    color: #000;
    margin-top: 1em;
    border: solid 1px white;
    margin-left: 1em;
    background-color: #EDEDED;
    border-radius: 5px;
    font-size: 16px;
}
.account-input:focus-visible {
    outline: 0px;
}

.no-underline {
    text-decoration: none;
    color: #BA3D1F;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 20px
}

.popup-buttons {
    margin-top: 20px;
}

.popup-button {
    background-color: #A7A7A7;
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
    color: white;
    width: 136px;
    height: 40px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    border: solid 1px white;
    cursor: pointer;
}

.home-button {
    margin-top: 10px;
    position: absolute;
    top: 1em;
    right: 2em;
    width: 146px;
    text-align: center;
    z-index: 99;
    display: flex;
    cursor: pointer;
    transition: all 0.3s ease;
}

.home-button:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.home-button .home-text {
    display: inline;
}
.home-button .home-icon-pc {
    width: 100%;
    height: auto;
    max-width: 146px;
}

.home-button .home-icon {
    display: none;
    width: 42px;
    height: 42px;
}

.warning-message {
    color: red;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    margin-top: 0.2em;
    text-align: left;
}

.popup-button.confirm {
    background-image: linear-gradient(to right, #FF9800, #FF4929);
}

.modal {
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    margin: auto;
    width: 90%;
    max-width: 1040px;
    position: relative;
}
.modal-content p {
    font-size: 18px;
}
.modal-content p img {
    max-width: 100%;
    height: auto;
}
.modal-content .close {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: #cbcbcb;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    background-color: #ff9900;
}
.thumb-popup-detail {
    width: 100%;
    height: auto;
}
.thumb-popup-detail .image-popup-detail {
    max-width: 100%;
    height: auto;
}
.tablet-image {
    display: none;
    position: fixed;
    bottom: 0;
    left: 20vw;
    width: 60vw;
    height: auto;
}

.input-form-captcha input {
    padding: 0 20px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgb(206, 206, 206);
    border-radius: 10px;
    letter-spacing: 1px;
}

.input-form-captcha-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}
.thumb-captcha{
    cursor: pointer;
    height: auto;
    display: flex;
}
.thumb-captcha .image-captcha{
    max-height: 50px;
    width: auto;
}

.input-form-captcha {
    width: 50%;
    height: 38px;
    display: flex;
}

#captcha-input,
.input-form input:focus {
    outline: none;
}

#captcha {
    width: 80px;
    margin-right: 16px;
    height: 36px;
    border-radius: 5px;
    font-size: 1.2em;
    background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 3px;
    font-family: 'Mulish', sans-serif;
    font-style: italic;
    text-decoration: line-through;
    color: rgb(255, 255, 255);
}
.swal2-confirm.swal2-styled{
    /* background-color: #cb442c; */
    background-image: linear-gradient(to right, #FF9800, #FF4929);
}

.swal2-confirm.swal2-styled:hover{
    background-color: #cb442c;
}

.modal-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modal-text-cont {
    display: flex;
}
.swal2-container.swal2-center.swal2-backdrop-show {
    overflow: hidden;
}
.swal2-popup.swal2-modal.swal2-show {
    max-width: 90%;
}
.swal2-icon.swal2-icon-show {
    width: 4em !important;
    height: 4em !important;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}
.swal2-x-mark .swal2-x-mark-line-left, .swal2-x-mark .swal2-x-mark-line-right{
    top: 28px !important;
    width: 32px !important;
    height: 4px !important;
}
.swal2-title {
    padding-top: 6px !important;
    font-size: 26px;
}
.swal2-html-container {
    padding-top: 6px !important;
}
.swal2-actions {
    margin-top: 8px;
}



.pyro>.before,.pyro>.after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: -120px -218.66667px blue,248px -16.66667px #00ff84,190px 16.33333px #002bff,-113px -308.66667px #ff009d,-109px -287.66667px #ffb300,-50px -313.66667px #ff006e,226px -31.66667px #ff4000,180px -351.66667px #ff00d0,-12px -338.66667px #00f6ff,220px -388.66667px #9f0,-69px -27.66667px #ff0400,-111px -339.66667px #6200ff,155px -237.66667px #0df,-152px -380.66667px #00ffd0,-50px -37.66667px #0fd,-95px -175.66667px #a6ff00,-88px 10.33333px #0d00ff,112px -309.66667px #005eff,69px -415.66667px #ff00a6,168px -100.66667px #ff004c,-244px 24.33333px #f60,97px -325.66667px #f06,-211px -182.66667px #00ffa2,236px -126.66667px #b700ff,140px -196.66667px #9000ff,125px -175.66667px #0bf,118px -381.66667px #ff002f,144px -111.66667px #ffae00,36px -78.66667px #f600ff,-63px -196.66667px #c800ff,-218px -227.66667px #d4ff00,-134px -377.66667px #ea00ff,-36px -412.66667px #ff00d4,209px -106.66667px #00fff2,91px -278.66667px #000dff,-22px -191.66667px #9dff00,139px -392.66667px #a6ff00,56px -2.66667px #09f,-156px -276.66667px #ea00ff,-163px -233.66667px #00fffb,-238px -346.66667px #00ff73,62px -363.66667px #08f,244px -170.66667px #0062ff,224px -142.66667px #b300ff,141px -208.66667px #9000ff,211px -285.66667px #f60,181px -128.66667px #1e00ff,90px -123.66667px #c800ff,189px 70.33333px #00ffc8,-18px -383.66667px #0f3,100px -6.66667px #ff008c;
    -moz-animation: 1s bang ease-out infinite backwards,1s gravity ease-in infinite backwards,5s position linear infinite backwards;
    -webkit-animation: 1s bang ease-out infinite backwards,1s gravity ease-in infinite backwards,5s position linear infinite backwards;
    -o-animation: 1s bang ease-out infinite backwards,1s gravity ease-in infinite backwards,5s position linear infinite backwards;
    -ms-animation: 1s bang ease-out infinite backwards,1s gravity ease-in infinite backwards,5s position linear infinite backwards;
    animation: 1s bang ease-out infinite backwards,1s gravity ease-in infinite backwards,5s position linear infinite backwards
  }
  
  .pyro>.after {
    -moz-animation-delay: 1.25s,1.25s,1.25s;
    -webkit-animation-delay: 1.25s,1.25s,1.25s;
    -o-animation-delay: 1.25s,1.25s,1.25s;
    -ms-animation-delay: 1.25s,1.25s,1.25s;
    animation-delay: 1.25s,1.25s,1.25s;
    -moz-animation-duration: 1.25s,1.25s,6.25s;
    -webkit-animation-duration: 1.25s,1.25s,6.25s;
    -o-animation-duration: 1.25s,1.25s,6.25s;
    -ms-animation-duration: 1.25s,1.25s,6.25s;
    animation-duration: 1.25s,1.25s,6.25s
  }
  
  @-webkit-keyframes bang {
    from {
        box-shadow: 0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff
    }
  }
  
  @-moz-keyframes bang {
    from {
        box-shadow: 0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff
    }
  }
  
  @-o-keyframes bang {
    from {
        box-shadow: 0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff
    }
  }
  
  @-ms-keyframes bang {
    from {
        box-shadow: 0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff
    }
  }
  
  @keyframes bang {
    from {
        box-shadow: 0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff,0 0 #fff
    }
  }
  
  @-webkit-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0
    }
  }
  
  @-moz-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0
    }
  }
  
  @-o-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0
    }
  }
  
  @-ms-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0
    }
  }
  
  @keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0
    }
  }
  
  @-webkit-keyframes position {
    0%,19.9% {
        margin-top: 10%;
        margin-left: 40%
    }
  
    20%,39.9% {
        margin-top: 40%;
        margin-left: 30%
    }
  
    40%,59.9% {
        margin-top: 20%;
        margin-left: 70%
    }
  
    60%,79.9% {
        margin-top: 30%;
        margin-left: 20%
    }
  
    80%,99.9% {
        margin-top: 30%;
        margin-left: 80%
    }
  }
  
  @-moz-keyframes position {
    0%,19.9% {
        margin-top: 10%;
        margin-left: 40%
    }
  
    20%,39.9% {
        margin-top: 40%;
        margin-left: 30%
    }
  
    40%,59.9% {
        margin-top: 20%;
        margin-left: 70%
    }
  
    60%,79.9% {
        margin-top: 30%;
        margin-left: 20%
    }
  
    80%,99.9% {
        margin-top: 30%;
        margin-left: 80%
    }
  }
  
  @-o-keyframes position {
    0%,19.9% {
        margin-top: 10%;
        margin-left: 40%
    }
  
    20%,39.9% {
        margin-top: 40%;
        margin-left: 30%
    }
  
    40%,59.9% {
        margin-top: 20%;
        margin-left: 70%
    }
  
    60%,79.9% {
        margin-top: 30%;
        margin-left: 20%
    }
  
    80%,99.9% {
        margin-top: 30%;
        margin-left: 80%
    }
  }
  
  @-ms-keyframes position {
    0%,19.9% {
        margin-top: 10%;
        margin-left: 40%
    }
  
    20%,39.9% {
        margin-top: 40%;
        margin-left: 30%
    }
  
    40%,59.9% {
        margin-top: 20%;
        margin-left: 70%
    }
  
    60%,79.9% {
        margin-top: 30%;
        margin-left: 20%
    }
  
    80%,99.9% {
        margin-top: 30%;
        margin-left: 80%
    }
  }
  
  @keyframes position {
    0%,19.9% {
        margin-top: 10%;
        margin-left: 40%
    }
  
    20%,39.9% {
        margin-top: 40%;
        margin-left: 30%
    }
  
    40%,59.9% {
        margin-top: 20%;
        margin-left: 70%
    }
  
    60%,79.9% {
        margin-top: 30%;
        margin-left: 20%
    }
  
    80%,99.9% {
        margin-top: 30%;
        margin-left: 80%
    }
  }


@media (min-width: 768px) and (max-width: 1024px) {
    .tablet-image {
        display: block;
        width: 60%;
        left: 0;
        z-index: 0;

    }
    .promo-title {
        font-size: 24px;
    }
    .body-content {
        position: absolute;
        top: 35%;
        transform: translateY(-50%);
        right: auto;
        height: auto;
        margin: 0 1em;
    }

    .promo-page {
        background: url('../images/Mobile1.jpg');
    }
}

@media (max-width: 767px) {
    .body-content {
        position: absolute;
        top: 1em;
        transform: unset;
        height: auto;
        width: 90%;
        margin: 0 auto;
        left: 5%;
    }

    .promo-page {
        background: url('../images/Mobile.jpg');
    }

    .popup {
        width: 96vw;
        padding: 10px 0;
    }

    .home-button {
        position: absolute;
        top: 0em;
        right: 0.5em;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        padding: 10px;
    }
    .home-button .home-icon-pc{
        display: none;
    }
    .home-button.tablet-image{
        display: flex;
        align-items: center;
    }
    .promo-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .thumb-logo {
        max-width: 252px;
        width: 90%;
    }

    .promo-form {
        max-width: 100%;
        border-radius: 10px;
        margin: 1em;
        padding: 0;
    }

    .promo-title {
        font-size: 20px;
        margin-bottom: 0;
    }

    .promo-label {
        font-size: 14px;
        margin-top: 0;
    }

    .promo-input {
        font-size: 14px;
        padding: 10px;
    }

    .promo-button {
        font-size: 14px;
        padding: 10px 20px;
        margin: 1em auto 0 auto;
    }

    .promo-links {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        margin-top: 0;
    }

    .promo-link {
        margin-top: 1em;
        margin-bottom: 0;
        text-align: left;
        background-color: #384154;
        border-radius: 5px;
        text-decoration: none;
        font-size: 12px;
    }
    .arrow {
        width: 20px;
        height: 20px;
    }

    .promo-link:hover {
        background-color: #e0e0e0;
    }

    .account-input,
    .popup-button {
        height: 40px;
        width: 90%;
        box-sizing: border-box;
    }

    .popup-button {
        display: inline-block;
        text-align: center;
        padding: 0 10px;
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        border-radius: 5px;
        text-decoration: none;
        color: #333;
        width: 150px;
    }

    .popup-button.confirm {
        background-image: linear-gradient(to right, #FF9800, #FF4929);
    }

    .home-button .home-text {
        display: none;
    }

    .home-button .home-icon {
        display: inline;
    }
    .modal-content {
        width: 92%;
    }
    .modal-content p {
        font-size: 16px;
    }
    .modal-content .close {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 32px;
        height: 32px;
    }
    .swal2-title {
        font-size: 23px;
    }
    .swal2-html-container {
        font-size: 16px !important;
    }
    .swal2-icon.swal2-icon-show {
        width: 3em !important;
        height: 3em !important;
        margin-bottom: 8px !important;
    }
    .swal2-x-mark .swal2-x-mark-line-left, .swal2-x-mark .swal2-x-mark-line-right {
        top: 23px !important;
        width: 26px !important;
        height: 3px !important;
    }
    .swal2-x-mark .swal2-x-mark-line-left {
        left: 12px !important;
    }
    .swal2-x-mark .swal2-x-mark-line-right {
        right: 12px !important;
    }
    .swal2-icon.swal2-warning .swal2-icon-content{
        font-size: 2.75em;
    }
}